import React from 'react'
import { motion } from "framer-motion";
import useWindowSize from '../../hooks/useWindowSize';


export default function ProgressCard({title , value ,icon}) {
  const {width} = useWindowSize()
  return (
  <>
   {width<=768 ? <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 17 }}
    className='lg:h-28 lg:w-64 h-24 w-full bg-white px-2   rounded-lg drop-shadow-lg   '>
 <div className='flex items-center justify-start mb-3 mt-3'>
{icon}
<p className='text-xs lg:text-sm pl-1 lg:pl-1'>{title}</p>
 </div>

<span className='text-2xl lg:text-3xl font-bold lg:pl-6 pl-7'>{value}</span>
    </motion.div > : <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 17 }}
    className='h-28 w-64 bg-white   rounded-lg drop-shadow-lg   '>
 <div className='flex items-center justify-start  mb-3 pl-6 mt-3'>
  {icon}
<p className='text-sm pl-1'>{title}</p>
 </div>

<span className='text-3xl font-bold pl-14'>{value}</span>
    </motion.div > }
  </>
  )
}
