import React from 'react'
import { CgOptions } from 'react-icons/cg'
import {GrSettingsOption,GrStorage} from "react-icons/gr"
import {BiBuildings} from "react-icons/bi"
import {RiBattery2ChargeLine} from "react-icons/ri"

export default function QuickCard() {
  return (
    <div  className=' bg-[rgb(237,237,237)] py-20 flex  flex-col items-center'>
    <h3 className='mb-10 lg:mb-20 text-2xl lg-text-start  text-center font-bold'>Quickly find what you’re looking for:</h3>
    <div className='lg:flex-row flex-col gap-y-5 lg:gap-y-0 flex items-center lg:gap-x-12  justify-between'>
      <div className='bg-white  px-10 rounded-xl py-8 flex flex-col items-center justify-center'>
  <GrSettingsOption size={30}/>
  <span>Effortlessly handle charging stations</span>
      </div>
      <div className='bg-white  px-10 rounded-xl py-8 flex flex-col items-center justify-center'>
  <BiBuildings size={30}></BiBuildings>
  <span>Instant resident access</span>
      </div>
      <div className='bg-white  px-10 rounded-xl py-8 flex flex-col items-center justify-center'>
  <GrStorage size={30}></GrStorage>
  <span>Generate revenue from your chargers.</span>
      </div>
      <div className='bg-white  px-10 rounded-xl py-8 flex flex-col items-center justify-center'>
      <RiBattery2ChargeLine size={30}></RiBattery2ChargeLine>
  <span>Better, easier charging</span>
      </div>
    </div>
  </div>
  
  )
}
