import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Col } from "antd";
export default function FeaturesCard({ icon, title, desc }) {
  return (
    <Col xs={24} md={12} lg={8} xxl={6} >
    <div className="bg-white  flex flex-col justify-center  items-center px-10 rounded-xl w-[250px]">
      <div className="flex items-center justify-center drop-shadow-xl bg-[#6CB75F] -translate-x-16 -translate-y-8  border-2 border-gray-400 h-14 rounded-full w-14">
        {icon}
      </div>
      <h2 className="text-lg w-full h-[40px] text-start font-medium mb-3">
        {title}
      </h2>
      <p className="text-sm text-gray-400 mt-3">{desc}</p>
      <div className=" mb-4 flex  items-center mt-3 translate-x-16 hover:text-white hover:bg-gray-400 cursor-pointer drop-shadow-xl justify-center border-2 border-gray-400 h-10 rounded-full w-10">
        <AiOutlineArrowRight size={15}></AiOutlineArrowRight>
      </div>
    </div>
    </Col>
  );
}
