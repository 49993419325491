import icon1 from "../assets/icon1.png"
import icon2 from "../assets/icon2.png"
import icon3 from "../assets/icon3.png"
import icon4 from "../assets/icon4.png"
import {FiBarChart2} from "react-icons/fi"
import {LiaChargingStationSolid} from "react-icons/lia"
import {PiBatteryPlusVerticalLight} from "react-icons/pi"
import {MdOutlineEnergySavingsLeaf} from "react-icons/md"
import {PiPlugCharging} from "react-icons/pi"
import {BsPeople} from "react-icons/bs"
import {BsLightningCharge} from "react-icons/bs"
import {BiWorld} from "react-icons/bi"
export const progressCardItems = [

   
    {
        title:"Total kg of CO2 saved",
        value:"44 035",
        icon:<MdOutlineEnergySavingsLeaf size={30} color={"#FAC133"}></MdOutlineEnergySavingsLeaf>,
        img:icon4
    },
    {
        title:"Partners",
        value:"546",
        icon:<BsPeople size={30} color={"#687DFE"}></BsPeople>,
        img:icon1
    },
    {
        title:"Accepted charge points",
        value:"387",
        icon:<PiPlugCharging size={30} color={"#FAC133"}></PiPlugCharging>,
        img:icon2
    },
    {
        title:"Roaming charge points",
        value:"249",
        icon:<BsLightningCharge size={30} color={"#02A78B"}></BsLightningCharge>,
        img:icon3
    },
    {
        title:"Countries",
        value:"12",
        icon:<BiWorld size={30} color={"#FEB5CF"}></BiWorld>,
        img:icon4
    },
    {
        title:"Successful charging rate",
        value:"98%",
        icon:<FiBarChart2 size={30} color={"#02A78B"}></FiBarChart2>,
        img:icon1
    },
    {
        title:"Charges performed monthly",
        value:"450K",

        icon:<LiaChargingStationSolid size={30} color={"#FEB5CF"}></LiaChargingStationSolid>,
        img:icon2
    },
    {
        title:"Charge points added monthly",
        value:"14576",

        icon:<PiBatteryPlusVerticalLight size={30} color={"#687DFE"}></PiBatteryPlusVerticalLight>,
        img:icon3
    },
]