import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { swiperItems } from "../utils/swiperItems";
import QuickCard from "../components/quickcard/QuickCard";
import DetailsCard from "../components/detailscard/DetailsCard";
import AppComp from "../components/appcomp/AppComp";
import { AccordionCustomAnimation } from "../components/accordion/AccordionCustomAnimation";
import menu from "../assets/menu.png";
import SwiperCustom from "../components/swiper/SwiperCustom";
import workForce from "../assets/workforce.avif";
import { Link } from "react-router-dom";

export default function DetailsPage({ img, title, description }) {
  const { width } = useWindowSize();

  return (
    <>
      {width <= 768 ? (
        <div className="overflow-x-hidden !flex justify-end">
          <div>
            <div
              className="bg-cover bg-center h-[100vh] flex justify-end"
              style={{ backgroundImage: `url(${workForce})` }}
            >
              <div className="flex flex-col items-center  mt-8  px-6 justify-center">
                <div className="w-full bg-white rounded-xl py-10 px-10">
                  <h1 className="text-4xl text-center font-bold mb-2 text-[#5CC0CA]">
                    {title}
                  </h1>
                  <p className="text-[#212A4F] text-center text-sm mb-8">
                    {description}
                  </p>
                  <Link
                    to="/login"
                    className="py-3 drop-shadow-xl w-full mb-10 bg-[#6BB85E] text-white px-4 rounded-xl"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>

            <div className="!flex !w-full justify-end">
              <QuickCard ></QuickCard>
            </div>

            <div className="py-20 bg-[#5CC0CA]  w-screen  ">
              <SwiperCustom items={swiperItems} direction={true}></SwiperCustom>
            </div>
            <AppComp></AppComp>
            <div className="my-20  bg-[#EDEDED]">
              <div className="w-10/12 m-auto py-20  space-y-3">
                <DetailsCard
                  title="Ease of use"
                  desc="Click&Plug boasts a user-friendly interface that simplifies the management of charging stations and energy consumption through a centralized system. This not only reduces complexity but also streamlines the entire charging process, making it more efficient and straightforward."
                ></DetailsCard>
                <DetailsCard
                  title="Flexible pricing"
                  desc="Establish the pricing structure that suits your needs for charging, and collect membership fees from residents who utilize your charging stations."
                ></DetailsCard>

                <DetailsCard
                  title="Versatile charging solutions"
                  desc="Enhance the EV charging experience for your residents with a seamless and user-friendly approach. Provide multiple access options to your chargers, including the Click&Plug app, RFID card tagging, QR code scanning, or the convenient Auto-start feature, catering to diverse preferences and ensuring a smooth experience."
                ></DetailsCard>

                <DetailsCard
                  title="Sustainability"
                  desc="By providing EV charging solutions, you contribute to eco-friendly efforts and help reduce your carbon footprint."
                ></DetailsCard>

                <DetailsCard
                  title="Scalable solution"
                  desc="Expand your charging infrastructure as demand grows with our scalable solutions, ensuring that your property can support an increasing number of EV drivers"
                ></DetailsCard>
              </div>
            </div>

            <div className="flex flex-col items-center w-10/12 m-auto">
              <img
                className="w-full object-cover object-center"
                src={menu}
                alt="menu"
              ></img>

              <div>
                <h1 className="text-3xl text-center font-bold mb-5">
                  Increase your residential business's income by introducing an
                  additional revenue stream.
                </h1>
                <p className="mb-5 text-center text-gray-400">
                  Offer your residents the convenience of charging their
                  electric vehicles at home while simultaneously creating a
                  lucrative income source. Customize pricing, establish
                  membership fees, and connect to our roaming network to
                  optimize your investment
                </p>
                <AccordionCustomAnimation></AccordionCustomAnimation>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-x-hidden">
          <div>
            <div
              className="bg-cover bg-center"
              style={{ backgroundImage: `url(${img})` }}
            >
              <div className="flex items-center h-screen w-9/12 m-auto justify-center">
                <div className="w-1/2 bg-white rounded-xl pl-5 py-10">
                  <h1 className="text-6xl font-bold mb-10 text-[#5CC0CA]">
                    {title}
                  </h1>
                  <p className="text-[#212A4F] mb-8">{description}</p>
                  <Link
                    to={"/login"}
                    className="py-3 drop-shadow-xl bg-[#6BB85E] text-white px-4 rounded-xl"
                  >
                    Get started
                  </Link>
                </div>
                <div className="w-1/2 rounded-xl"></div>
              </div>
            </div>

            <div>
              <QuickCard></QuickCard>
            </div>

            <div className="py-20 bg-[#1E293B]  w-screen  ">
              <h1 className="text-3xl font-bold  text-center text-white mb-10">
                We collaborate with top-tier networks across Europe.
              </h1>

              <SwiperCustom items={swiperItems} direction={true}></SwiperCustom>
            </div>
            <AppComp></AppComp>

            <div className=" bg-[#EDEDED] py-20">
              <div className="flex  items-center gap-x-5 w-10/12 m-auto">
                <div className="w-full">
                <DetailsCard
                  title="Ease of use"
                  desc="Click&Plug boasts a user-friendly interface that simplifies the management of charging stations and energy consumption through a centralized system. This not only reduces complexity but also streamlines the entire charging process, making it more efficient and straightforward."
                ></DetailsCard>
                </div>
                <div className="w-full">
                <DetailsCard
                  title="Flexible pricing"
                  desc="Establish the pricing structure that suits your needs for charging, and collect membership fees from residents who utilize your charging stations."
                ></DetailsCard>
                </div>
              </div>
              <div className="lg:flex-row flex-col  flex items-center gap-x-5 w-10/12 m-auto mt-5">
                <div>
                <DetailsCard
                  title="Versatile charging solutions"
                  desc="Enhance the EV charging experience for your residents with a seamless and user-friendly approach. Provide multiple access options to your chargers, including the Click&Plug app, RFID card tagging, QR code scanning, or the convenient Auto-start feature, catering to diverse preferences and ensuring a smooth experience."
                ></DetailsCard>
                </div>
                <div>
                <DetailsCard
                  title="Sustainability"
                  desc="By providing EV charging solutions, you contribute to eco-friendly efforts and help reduce your carbon footprint."
                ></DetailsCard>
                </div>

                <div>
                <DetailsCard
                  title="Scalable solution"
                  desc="Expand your charging infrastructure as demand grows with our scalable solutions, ensuring that your property can support an increasing number of EV drivers"
                ></DetailsCard>
                </div>
              </div>
            </div>

            <div className="flex items-center w-10/12 m-auto">
              <div>
                <h1 className="text-4xl font-bold mb-5">
                  Increase your residential business's income by introducing an
                  additional revenue stream.
                </h1>
                <p className="mb-5 text-gray-400">
                  Offer your residents the convenience of charging their
                  electric vehicles at home while simultaneously creating a
                  lucrative income source. Customize pricing, establish
                  membership fees, and connect to our roaming network to
                  optimize your investment
                </p>
                <AccordionCustomAnimation></AccordionCustomAnimation>
              </div>
              <img
                className="w-1/2 object-cover object-center"
                src={menu}
                alt="menu"
              ></img>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
