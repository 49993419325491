import React from "react";

import { Route, Routes, } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";
import ChargePoint from "../pages/ChargePoint";
import Chargingongo from "../pages/Chargingongo";
import Commercial from "../pages/Commercial";
import Fleet from "../pages/Fleet";
import Homecharging from "../pages/Homecharging";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import Operators from "../pages/Operators";
import ResidentalPage from "../pages/ResidentalPage";
import Utilities from "../pages/Utilities";
import Workplace from "../pages/Workplace";
import Wholesaler from "../pages/Wholesaler";
import Roamingnetwork from "../pages/Roamingnetwork";
import Blog from "../pages/Blog";
import Press from "../pages/Press";
import Casestudies from "../pages/Casestudies";
import About from "../pages/About";
import Features from "../pages/Features";

export default function Routing() {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route exact path="/" element={<LandingPage></LandingPage>} />
        <Route
          path="/residental"
          element={<ResidentalPage></ResidentalPage>}
        ></Route>
        <Route path="/workplace" element={<Workplace></Workplace>}></Route>

        <Route path="/commercial" element={<Commercial></Commercial>}></Route>

        <Route path="/fleet" element={<Fleet></Fleet>}></Route>
        <Route
          path="/chargepoint"
          element={<ChargePoint></ChargePoint>}
        ></Route>
        <Route path="/operators" element={<Operators></Operators>}></Route>

        <Route path="/utilities" element={<Utilities></Utilities>}></Route>

        <Route
          path="/homecharging"
          element={<Homecharging></Homecharging>}
        ></Route>
        <Route
          path="/chargingongo"
          element={<Chargingongo></Chargingongo>}
        ></Route>
        <Route path="/wholesaler" element={<Wholesaler></Wholesaler>}></Route>

        <Route
          path="/roamingnetwork"
          element={<Roamingnetwork></Roamingnetwork>}
        ></Route>

        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/press" element={<Press></Press>}></Route>

        <Route
          path="/casestudies"
          element={<Casestudies></Casestudies>}
        ></Route>

        <Route path="/about" element={<About></About>}></Route>
        <Route path="/contact" element={<About></About>}></Route>
        <Route path="/features" element={<Features></Features>}></Route>

        <Route></Route>

        <Route path="/login" element={<LoginPage></LoginPage>}></Route>
      </Route>
    </Routes>
  );
}
