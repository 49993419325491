import React from "react";
import { motion } from "framer-motion";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "react-router-dom";

export default function HomeCard() {
  const { width } = useWindowSize();

  return (
    <div>
      {width <= 768 ? (
        <div className="flex items-center justify-center h-screen w-screen">
          <div className="h-[300px] w-10/12  bg-white flex items-center rounded-md justify-center ">
            <div className="flex flex-col text-center ">
              <p className="font-semibold text-base mb-2 tracking-widest">
                A platform
              </p>
              <h1 className="text-2xl font-bold mb-2 text-[#212A4F] ">
                Designed for electric vehicle
              </h1>
              <p className="text-[#9B9B9B] text-xs mb-5 px-4 ">
                Within the electric vehicle charging ecosystem, Click & Plug
                reigns as the ultimate powerhouse. We offer an all-encompassing
                software suite that caters to the diverse needs of electric
                vehicle charging, serving everyone from commercial drivers to
                community-based workers.
              </p>
              <Link to="/login">
                {" "}
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="bg-[#212A4F] drop-shadow-lg m-auto w-1/3 text-xs py-3 rounded-lg text-white"
                >
                  Get Started
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[443px] w-[610px] pl-10 bg-white flex items-center bottom-28 right-28 rounded-md absolute justify-center">
          <div className="flex flex-col justify-center w-full h-full">
            <p className="font-semibold text-base mb-5 tracking-widest">
              A platform
            </p>
            <h1 className="text-5xl font-bold mb-5 text-[#212A4F] leading-[65px]">
              Designed for electric vehicle
            </h1>
            <p className="text-[#9B9B9B] mb-8 pr-5">
              Within the electric vehicle charging ecosystem, Click & Plug
              reigns as the ultimate powerhouse. We offer an all-encompassing
              software suite that caters to the diverse needs of electric
              vehicle charging, serving everyone from commercial drivers to
              community-based workers.
            </p>
            <Link to="/login">
              <motion.button
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="bg-[#212A4F] drop-shadow-lg w-1/3 py-5 rounded-lg text-white"
              >
                Get Started
              </motion.button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
