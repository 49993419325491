import React from "react";
import DetailsPage from "./DetailsPage";
import img3 from "../assets/img3.jpg";
import PageLayout from "../layouts/PageLayout";
import Modal from "../components/modal/Modal";
export default function Fleet() {
  return (
    <div>
      <DetailsPage
        img={img3}
        title={"Manage EV fleet charging at any scale"}
        description={
          "Click&Plug supports the charging needs of electric-vehicle fleets of all types and sizes. Keep your electric fleet on the move with our complete EV fleet management solution."
        }
      ></DetailsPage>
      <Modal title={"Fleet"}></Modal>
    </div>
  );
}
