import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import {  Zoom,Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/zoom';

import useWindowSize from "../../hooks/useWindowSize";

export default function SwiperCustom({ items, direction }) {
  const {width} = useWindowSize()
  return (
    <Swiper
      loop={true}
      zoom={true}
      slidesOffsetAfter={-100}
      slidesOffsetBefore={-100}
      slidesPerView={
        width <= 768 ? 2:
        (width <= 960 && width > 768 ? 4 :
        (width <= 1024 ? 4 : 6))
      }
            spaceBetween={width <= 768 ? 200 : 150}
      freeMode={true} 
      speed={9000}
      autoplay={{
        delay: 1000,

        disableOnInteraction: true,
       
        loop: true,
      }}
      modules={[ Zoom,Autoplay, Pagination, Navigation]}
      className="w-screen h-full text-center flex items-center   "
    >
      {items.map((item) => (
        <SwiperSlide>
          <div className=" w-48 lg:w-48 h-28 lg:h-52 bg-white  flex items-center justify-center rounded-xl drop-shadow-lg">
            <img
              alt="logo"
              className="object-center object-cover   "
              src={item.img}  
            ></img>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
