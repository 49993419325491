
import {
    FiEdit,
    FiTrash,
    FiShare,
    FiPlusSquare,
  } from "react-icons/fi";
  import img1 from "../assets/img1.jpg"
export const solutionsOptions = [

    {
        icon : FiEdit ,
        text :"Residental",
        link:"/residental",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."
    },
    {
        icon :  FiPlusSquare, 
        text :"Workplace & offices",
        link:"/workplace",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiShare ,
        text :"Commercial",
        link:"/commercial",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."


    },
    {
        icon : FiTrash ,
        text :"Fleet",
        link:"/fleet",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Charge point insallers",
        link:"/chargepoint",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Operators",
        link:"/operators",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Utilities",
        link:"/utilities",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Home charging",
        link:"/homecharging",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Charging on the go",
        link:"/chargingongo",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Wholesaler",
        link:"/wholesaler",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
    {
        icon : FiTrash ,
        text :"Roaming network",
        link:"/roamingnetwork",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    },
   
]


export const companyOptions = [

    
   
   
    {
        icon : FiTrash ,
        text :"Contact",
        link:"/contact",
        img:img1,
        title:"Power up your workforce",
        description:"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."

    }
    
   
]