import React from "react";
import logo from "../../assets/logoWhite.png";
import Form from "./Form";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import {Col,Row} from "antd"

export default function Footer() {
 

  return (
    <Col span={24} className="bg-[#1F242C] pt-28 ">
      <div className="flex items-center justify-center">
        <img
          className="object-cover object-center h-16 mb-16"
          src={logo}
          alt="logo"
        ></img>
      </div>
     
      <div className="border-b-2 border-[#6BB85E] mb-16"></div>
      <Row>
        
      <div className="flex items-start justify-between w-10/12 m-auto mb-28">
        <Row className="!w-full">
          <Col xs={24} lg={18} >
          <div className="flex  ">
        <div>
          <h2 className="mb-3 text-[#5CC0CA] font-bold text-lg">Solutions</h2>
          <ul className="space-y-3">
            <li className=" text-sm text-white">
              <Link to="/residental">Residental</Link>
            </li>
            <li className=" text-sm text-white">
              <Link to="/workplace">Workplace & offices</Link>
            </li>

            <li className=" text-sm text-white">
              <Link to="/commercial">Commercial</Link>
            </li>

            <li className=" text-sm text-white">
              <Link to="/fleet">Fleet</Link>
            </li>

            <li className=" text-sm text-white">
              <Link to="/chargepoint">Charge point insallers</Link>
            </li>
            <li className=" text-sm text-white">
              <Link to="/homecharging">Home charging</Link>
            </li>
           
            <li className=" text-sm text-white">
              <Link to="/roamingnetwork">Roaming Network</Link>
            </li>
          </ul>
        </div>

        <div className="!pl-40">
          <h2 className="mb-3 text-[#5CC0CA] font-bold text-lg">Company</h2>
          <ul className="space-y-3">
            <li className=" text-sm text-white">
              <Link to="/about">About</Link>
            </li>
            <li className=" text-sm text-white ">
              <Link to="/features">Features</Link>
            </li>
            <li className=" text-sm text-white">
              <Link to="/">Home</Link>
            </li>
            <li className=" text-sm text-white">
              <Link to="/login">Booking Demo</Link>
            </li>
          
           
          </ul>
        </div>
       
            
          </div>
          </Col>
          <Col xs={24} lg={6} >
       <Form/>
          </Col>
        </Row>
      </div>
       
      </Row>
      
      <div className="border-b-2 w-full border-[#6BB85E] mb-8"></div>
      <div className="justify-between items-center flex w-10/12 m-auto">
        <span className="text-xs text-[#6F6C90]">
          Copyright © 2023 Click And Plug Templates | All Rights Reserved{" "}
        </span>
        <div className="flex items-center gap-x-4 mb-6">
          <Link
            target="_blank"
            to="https://www.instagram.com/madeingraphiclondon/"
          >
            <AiFillInstagram color="#5CC0CA" size={25}></AiFillInstagram>
          </Link>
          <Link
            target="_blank"
            to="https://www.google.com/maps/place/12+Sutherland+Grove,+London+SW18+5PS/@51.4456613,-0.2098856,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f46db7562f3:0xdfcdd8da0d017276!8m2!3d51.4456613!4d-0.2073107?entry=ttu"
          >
            <FaLocationDot color="#5CC0CA" size={22}></FaLocationDot>
          </Link>
        </div>
      </div>
      {/* <div className="bg-gradient-to-r from-[#6CB75F]   h-[14px] to-[#5DC0CA]"></div> */}
    </Col>
   
  );
}
