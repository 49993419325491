import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Label from "../components/Label";
import { Outlet } from "react-router-dom";

export default function PageLayout({ children }) {
  return (
   <>
      <Navbar/>
     
       <Outlet/>
       
      

     <Footer/>
   </>
   
  );
}
