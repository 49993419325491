import React from 'react'
import SolutionCard from '../components/solutioncard/SolutionCard'
import { solutionCardItems } from '../utils/SolutionCardItems'

export default function SolutionPage() {
  return (
    <div className='bg-[#ededed] py-28  '>
        <h1 className='text-2xl lg:text-4xl font-bold text-center mb-10 lg:mb-20 '>Discover our solutions
</h1>
    <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 lg:px-0 gap-2 px-6 w-full place-items-center lg:w-9/12 m-auto lg:gap-16  '>
    {solutionCardItems.map((item)=>(
        <SolutionCard description={item.description} title={item.title} color={item.color} icon={item.icon}></SolutionCard>
    ))}
    </div>
    </div>
  )
}
