import React from 'react'
import ProgressCard from '../components/progresscard/ProgressCard'
import { progressCardItems } from '../utils/ProgressCardItems'

export default function ProgressPage() {
  return (
    <div className='bg-[#ededed] py-28 '>
    <h1 className='text-2xl w-10/12 m-auto lg:w-full lg:text-4xl font-bold text-center mb-20 '>
    Empowerment through progress, one charge at a time
</h1>
<div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-items-center px-6 gap-3 w-full lg:w-9/12 m-auto lg:gap-16  '>
{progressCardItems.map((item)=>(
    <ProgressCard value={item.value} title={item.title} icon={item.icon}></ProgressCard>
))}
</div>
</div>
  )
}
