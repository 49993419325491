import React, { useState, } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai";
import useWindowSize from "../../hooks/useWindowSize";

const Modal = ({ title }) => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isModalOpen") === "true" ? true : false
  );
  const { width } = useWindowSize();

  const closeModal = () => {
    setIsOpen(false);
    localStorage.setItem("isModalOpen", "false");
  };

  return (
    <div className="px-4 py-2 bg-slate-900 grid place-content-center">
      <SpringModal
        width={width}
        title={title}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </div>
  );
};

const SpringModal = ({ width, title, isOpen, closeModal }) => {
  return (
    <>
      {width <= 768 ? (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-transparent backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
            >
              <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="bg-gradient-to-br from-[#6CB75F] to-[#5DC0CA] text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
              >
                <AiFillCloseCircle
                  size={30}
                  className="cursor-pointer"
                  onClick={closeModal}
                >
                  close
                </AiFillCloseCircle>
                <div>
                  <h3 className="text-2xl font-bold text-center mb-2">
                    {title}
                  </h3>
                </div>
                <div className="flex flex-col items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3 mb-3 mt-3"
                    placeholder="Name/Surname"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3"
                    placeholder="Phone Number"
                  ></input>
                </div>
                <div className="flex flex-col items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3"
                    placeholder="E-Mail"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3"
                    placeholder="City"
                  ></input>
                </div>
                <div className="flex flex-col items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3 mb-3"
                    placeholder="District"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3"
                    placeholder="Charging station type"
                  ></input>
                </div>
                <div className="flex flex-col items-center mb-3 justify-between text-black ">
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3 mb-3"
                    placeholder="Business partnership model"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-1 px-3"
                    placeholder="Location type"
                  ></input>
                </div>
                <div>
                  <textarea
                    className="w-full h-20 rounded-xl mb-3 p-2"
                    placeholder="Description"
                  ></textarea>
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    onClick={closeModal}
                    className="bg-white hover:opacity-90  transition-opacity text-[#5DC0CA] font-semibold px-5 rounded-xl py-2 "
                  >
                    Send
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-transparent backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
            >
              <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="bg-gradient-to-br from-[#6CB75F] to-[#5DC0CA] text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
              >
                <AiFillCloseCircle
                  size={30}
                  className="cursor-pointer"
                  onClick={closeModal}
                >
                  Close
                </AiFillCloseCircle>
                <div>
                  <h3 className="text-3xl font-bold text-center mb-2">
                    {title}
                  </h3>
                </div>
                <div className="flex items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="Name/Surname"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="Phone Number"
                  ></input>
                </div>
                <div className="flex items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="E-Mail"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="City"
                  ></input>
                </div>
                <div className="flex items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="District"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="Charging station type"
                  ></input>
                </div>
                <div className="flex items-center mb-3 justify-between text-black">
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="Business partnership model"
                  ></input>
                  <input
                    type="text"
                    className="rounded-xl py-2 px-3"
                    placeholder="Location type"
                  ></input>
                </div>
                <div>
                  <textarea
                    className="w-full h-24 rounded-xl mb-3 p-2"
                    placeholder="Description"
                  ></textarea>
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    onClick={closeModal}
                    className="bg-white hover:opacity-90  transition-opacity text-[#5DC0CA] font-semibold px-5 rounded-xl py-2 "
                  >
                    Send
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default Modal;
