import React from "react";
import DetailsPage from "./DetailsPage";
import img10 from "../assets/img10.jpg";
import PageLayout from "../layouts/PageLayout";
import Modal from "../components/modal/Modal";
export default function Workplace() {
  return (
    <div>
      <DetailsPage
        img={img10}
        title={"EV charging for workplaces"}
        description={
          "Ready to be the superhero your employees are looking for? Provide workplace charging or reimburse employees for charging their vehicles outside of work to expedite the green transition."
        }
      ></DetailsPage>
      <Modal title={"Workplace & offices"}></Modal>
    </div>
  );
}
