import React from 'react'
import DetailsPage from './DetailsPage'
import img1 from "../assets/img1.jpg"
import PageLayout from '../layouts/PageLayout'
import Modal from '../components/modal/Modal'
export default function Chargingongo() {
  return (
    <div>
  
  <DetailsPage img={img1} title={"Power up your workforce"}
      description={"Are you ready to be the hero your team deserves? Think about offering workplace charging stations or reimbursing employees for charging their electric vehicles when not at work. This step can be pivotal in hastening the transition to a more environmentally friendly future"}
      ></DetailsPage>
                  <Modal title={"Charging on the go"}></Modal>

 

    </div>
  )
}
