import React from 'react'
import DetailsPage from './DetailsPage'
import img1 from "../assets/img1.jpg"
import PageLayout from '../layouts/PageLayout'
import Modal from '../components/modal/Modal'
export default function Operators() {
  return (
    <div>
 
  <DetailsPage img={img1} title={"The all-in-one hub for your charge point operations"}
      description={"Designed for charge point operators, Click&Plug's Charge Point Management System (CPMS) empowers you to seamlessly install and manage an extensive array of charge points. It streamlines manual administrative tasks and enhances efficiency, ensuring exceptional customer experiences. Unleash the full potential of your EV-charging operations with Click&Plug today."}
      ></DetailsPage>
                  <Modal title={"Operators"}></Modal>

  

    </div>
  )
}
