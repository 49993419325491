import React from 'react'
import DetailsPage2 from './DetailsPage2'
import PageLayout from '../layouts/PageLayout'

export default function Blog() {
  return (
<div>


    
    <DetailsPage2 title={"Blog"} description={"Find useful blog posts, news, and industry insights on EV charging and the EV ecosystem."}></DetailsPage2>
    

    </div>
  )
}
