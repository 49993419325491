import React from "react";
import DetailsPage from "./DetailsPage";
import img2 from "../assets/img2.jpg";
import PageLayout from "../layouts/PageLayout";
import Modal from "../components/modal/Modal";
export default function Commercial() {
  return (
    <div>
      <DetailsPage
        img={img2}
        title={"Commercial EV charging solution"}
        description={
          "Click&Plug makes it simple for EV drivers to charge their vehicles at your retail location. You can list your commercial property on the Click&Plug network, which can help you attract new customers to your stores and enhance your business."
        }
      ></DetailsPage>
      <Modal title={"Commercial"}></Modal>
    </div>
  );
}
