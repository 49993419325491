import React from 'react';
import { color, motion } from 'framer-motion';
import useWindowSize from '../../hooks/useWindowSize';

export default function SolutionCard({ icon, title, description, color }) {
  const { width } = useWindowSize();

  if (width <= 768) {
    return (
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        className='h-24 w-full flex items-center  bg-white border-gray-400 border-2 rounded-lg drop-shadow-lg'
      >
        <div className='w-full h-full flex flex-col lg:pl-4 pl-2 py-2'>
          <div className='flex items-center '>{icon}
          <h1 style={{color:color}} className='mb-1 text-base pl-2  lg:text-base font-bold'>{title}</h1>

          </div>
          <div className='pl-7 lg:pl-4  mt-1'>
            <p className='lg:text-sm text-xs '>{description}</p>
          </div>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        className='h-28 w-64 bg-white  border-gray-400 border-2 rounded-lg drop-shadow-lg'
      >
        <div className='w-full h-full  flex flex-col pl-4 py-2'>
          <div className='flex items-center '>{icon}
          <h1 style={{color:color}} className='mb-1 pl-1 text-base font-bold'>{title}</h1>

          </div>
          <div className='pl-7 mt-1'>
            <p className='text-xs'>{description}</p>
          </div>
        </div>
      </motion.div>
    );
  }
}
