import React from "react";
import app from "../../assets/client-dashboard-box.jpeg";
import useWindowSize from "../../hooks/useWindowSize";
export default function AppComp() {
  const { width } = useWindowSize();

  return (
    <>
      {width <= 768 ? (
        <div className="flex  items-center w-10/12 m-auto my-20 justify-between">
          <div className="w-full">
            <h2 className="text-2xl text-center  font-bold mb-6">
              Offer your employees EV charging benefits, both at work and in the
              comfort of their homes
            </h2>
            <p className="text-sm">
              Our Charge Point Management System (CPMS) is designed to make
              charger management a hassle-free process. With just a few clicks,
              you can easily set up and install charging stations, onboard new
              members, customize pricing structures, grant access to your
              chargers, and swiftly reimburse employees for their home charging
              expenses. In addition, Click&Plug's seamless integration with your
              fleet provides a user-friendly and comprehensive overview of
              charging stations and their usage, ensuring that you have full
              control and visibility of your charging infrastructure.
            </p>
            <img
              alt="app"
              className="h-64 mt-4 object-cover object-center"
              src={app}
            ></img>
          </div>
        </div>
      ) : (
        <div className="flex lg:flex-row flex-col items-center w-10/12 m-auto gap-x-8 my-20 justify-between">
          <img
            alt="app"
            className="w-1/2 object-cover object-center"
            src={app}
          ></img>
          <div className="w-full">
            <h2 className="text-3xl  font-bold mb-6">
              Provide electric vehicle charging benefits to your employees,
              whether they're at their workplace or the comfort of their homes
            </h2>
            <p className="text-base">
              Our Charge Point Management System (CPMS) simplifies the
              administration of charging stations. With just a few clicks, you
              can install charging stations, onboard members, set pricing, grant
              access to chargers, and efficiently reimburse employees for home
              charging. Furthermore, Click&Plug seamlessly integrates with your
              fleet, providing a convenient overview of charging stations and
              usage.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
