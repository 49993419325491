import React from 'react'
import blog2 from "../../assets/blog2.png"
export default function BlogCard() {
  return (
    <div>

<div className='bg-white rounded-xl w-[300px] lg:w-[400px]'>
        <img className='rounded-xl object-center object-cover' src={blog2} alt="blog"></img>
          <div className='pl-3 py-3'>
          <span className='text-xl '>Click & Plug Features</span>
            <h2 className='text-sm my-2'>AutoCharge: Seamless and convenient EV charging today</h2>
          </div>
      </div>
    </div>
  )
}
