import React from "react";
import PageLayout from "../layouts/PageLayout";
import FeaturesCard from "../components/featurescard/FeaturesCard";
import { RiSecurePaymentFill } from "react-icons/ri";
import { GiCharging } from "react-icons/gi";
import { GiSolarPower } from "react-icons/gi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiWirelessChargingFill } from "react-icons/ri";
import { BsCreditCardFill } from "react-icons/bs";
import { HiPuzzlePiece } from "react-icons/hi2";
import { BsGraphUp } from "react-icons/bs";
import { ImPriceTags } from "react-icons/im";
import { AiFillClockCircle } from "react-icons/ai";
import { MdCardMembership } from "react-icons/md";
import { BiBarcodeReader } from "react-icons/bi";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { BsBatteryCharging } from "react-icons/bs";
import { GiHealingShield } from "react-icons/gi";
export default function Features() {
  return (
    <div className=" py-20 bg-[#EDEDED]">
      <h1 className="text-4xl text-center text-[#6CB75F] font-semibold mt-20 mb-5  ">
        Product Features
      </h1>
      <p className="text-center text-gray-400 text-lg">
        Explore the robust functionalities of Click & Plug that provide
        advantages to all stakeholders within the electric vehicle charging
        ecosystem.
      </p>

      <div className="grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 mt-20 w-10/12 m-auto gap-y-10   grid place-items-center">
        <FeaturesCard
          desc={
            "Convenient charging, smooth transactions, and effortless billing"
          }
          icon={
            <RiSecurePaymentFill size={30} color="white"></RiSecurePaymentFill>
          }
          title={"Payment choices and billings"}
          des
        ></FeaturesCard>
        <FeaturesCard
          desc={"Initiate charging by plugging in and end it by unplugging"}
          icon={<GiCharging size={30} color="white"></GiCharging>}
          title={"Auto Charge"}
        ></FeaturesCard>
        <FeaturesCard
          desc={"Promoting individual ease and contributing to the transition."}
          icon={<FaPeopleGroup size={30} color="white"></FaPeopleGroup>}
          title={"Public API"}
        ></FeaturesCard>
        <FeaturesCard
          desc={
            "The process of connecting charge points to Click&Plug is both straightforward and secure"
          }
          icon={
            <RiWirelessChargingFill
              size={30}
              color="white"
            ></RiWirelessChargingFill>
          }
          title={"OCPP"}
        ></FeaturesCard>
        <FeaturesCard
          desc={
            "The seamless integration of Payter Terminals with EV drivers empowers them to make credit card payments directly at charging stations"
          }
          icon={<BsCreditCardFill size={30} color="white"></BsCreditCardFill>}
          title={"Payment Terminal"}
        ></FeaturesCard>
        <FeaturesCard
         
          icon={<HiPuzzlePiece size={30} color="white"></HiPuzzlePiece>}
          title={"Partner API"}
          desc={
            "Click&Plug's DynamicPricing feature empowers operators to establish their kilowatt-hour (kWh) pricing according  "
          }
        ></FeaturesCard>
        <FeaturesCard
         
          icon={<BsGraphUp size={30} color="white"></BsGraphUp>}
          title={"Reports"}
          desc={"Receive detailed reports concerning any issue related to a charge point integrated "}
        ></FeaturesCard>
        <FeaturesCard
          icon={<ImPriceTags size={30} color="white"></ImPriceTags>}
          title={"Set Different Pricing"}
          desc={"Administer charging stations at an individual level and determine pricing structures for various user groups"}
        ></FeaturesCard>
       
       
       
       
        <FeaturesCard
          icon={<BsBatteryCharging size={30} color="white"></BsBatteryCharging>}
          title={"Instant Charge"}
          desc={"With app clips, EV drivers can charge and pay without having to download the app. It’s convenient for the majority of EV drivers"}
        ></FeaturesCard>
        <FeaturesCard
          icon={<GiHealingShield size={30} color="white"></GiHealingShield>}
          title={"Smart booking"}
          desc="Secure a charging point reservation to ensure you won't encounter occupied charging stations. When a charge point is reserved, only you have the authority to initiate the charging session."
        ></FeaturesCard>
         <FeaturesCard
          icon={<GiHealingShield size={30} color="white"></GiHealingShield>}
          title={"Load balancing"}
          desc="Choose from Static, Dynamic, or True Dynamic options for intelligent electricity distribution, eliminating the need to invest in costly additional amperage."
        ></FeaturesCard>
      </div>
    </div>
  );
}
