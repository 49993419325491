import React from 'react'
import DetailsPage from './DetailsPage'
import img9 from "../assets/img9.jpg"
import PageLayout from '../layouts/PageLayout'
import Modal from '../components/modal/Modal'
export default function Roamingnetwork() {
  return (
    <div>
  
  <DetailsPage img={img9} title={"Give drivers the best charging experience—everywhere"}
      description={"As a Charge Point Operator (CPO) in the fast-evolving EV charging industry, gain a competitive advantage by joining Click&Plug's Roaming network. Utilize Click&Plug's data-driven insights to optimize your infrastructure, improve charge point utilization, and connect with Europe's rapidly expanding EV community."}
      ></DetailsPage>
                  <Modal title={"Roaming Network"}></Modal>

  

    </div>
  )
}
