import React from "react";
import BlogCard from "../components/blogcard/BlogCard";

export default function DetailsPage2({ title, description }) {
  return (
    <div className="bg-[#EDEDED] pb-10 py-1 lg:py-20">
      <h1 className="text-4xl lg:w-full w-10/12 m-auto lg:text-5xl text-[#5CC0CA] font-bold text-center mt-20">{title}</h1>
      <p className="text-center lg:w-full w-10/12 m-auto mb-10 mt-3">{description}</p>
     <div className="grid w-10/12   m-auto grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-y-12 place-items-center">
<BlogCard></BlogCard>
<BlogCard></BlogCard>

<BlogCard></BlogCard>

<BlogCard></BlogCard>

<BlogCard></BlogCard>

<BlogCard></BlogCard>

      </div>
    </div>
  );
}
