import React from "react";
import { BiSolidLike } from "react-icons/bi";
export default function DetailsCard(props) {
  return (
    <div>
      <div className="flex items-center ">
        <div className="w-full">
          <div className="bg-white py-8 px-5 lg:px-10 rounded-xl">
            <div className="flex items-center gap-x-2  mb-3">
             
              <h4 className="text-lg lg:text-xl font-medium">
               {props.title}
              </h4>
            </div>
            <p className="lg:text-base text-sm">
             {props.desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
