import React,{useState} from "react";
import { motion } from "framer-motion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';



export default function Form() {
  const style = `
  .react-tel-input .form-control
  {
    height:45px !important;
    width:100% !important;
    border-radius:12px !important;
  }
  `
  const [isLoading,setIsLoading] = useState(false)
  const handleFormSubmit = (e)=>{
    
    e.preventDefault()
    setIsLoading(true)
    setTimeout(()=>{
      setIsLoading(false)
    },2000)
    toast.success("Your request sended")
    
    

  }
  return (
    <div>
      <h1 className="text-[#5CC0CA] font-bold text-base lg:text-lg  mb-2 lg:mb-5">
        Contact Us
      </h1>
      <div className="bg-[#54ADBB] p-4 rounded-xl">
        <form onSubmit={handleFormSubmit} className="flex flex-col pt-5">
          <div className="flex justify-between mb-2  items-center">
            <input
              required
              className="p-2  w-4/6 rounded-xl mr-2 !text-sm"
              type="text"
              placeholder="Name"
            ></input>
            <input
              required
              className="p-2  w-4/6 rounded-xl !text-sm"
              type="text"
              placeholder="Last Name"
            ></input>
          </div>
          <input
            required
            className="p-2  w-full rounded-xl mr-2 mb-2 !text-sm"
            type="text"
            placeholder="Email"
            email={true}
          ></input>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
              size:"large",
             
             
              placeholder:"Phone Number"
            }}
          />
          <textarea required placeholder="Description" className="!rounded-lg !my-2 !pl-2 !pt-2" rows={4} ></textarea>

          <motion.button
          disabled={isLoading}
            type="submit"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="bg-[#212A4F] w-1/3 text-white m-auto py-2 mt-1 rounded-xl"
          >
            Send
          </motion.button>
        </form>
      </div>
      <style>{style}</style>
    </div>
  );
}
