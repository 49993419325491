import React from 'react';
import bgmockup2 from "../assets/bgmockup2.jpg";
import mockup from "../assets/client-quick-view.jpeg";

export default function MockupPage() {
  return (
    <div className='h-screen bg-center  bg-cover' style={{ backgroundImage: `url(${bgmockup2})` }}>
      <div className=' lg:flex  w-10/12 lg:flex-row flex-col flex m-auto justify-center items-center h-full'>
        <img alt='mockup' className='w-full lg:w-1/2 object-cover' src={mockup} />
        <h1 className='text-3xl lg:text-5xl font-semibold text-center mt-4 text-[#212A4F]'>
        The preferred platform for the foremost electric vehicle suppliers worldwide
        </h1>
      </div>
    </div>
  );
}
