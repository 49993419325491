import React from "react";
import DetailsPage from "./DetailsPage";
import img5 from "../assets/img5.jpg";
import PageLayout from "../layouts/PageLayout";
import Modal from "../components/modal/Modal";
export default function Utilities() {
  return (
    <div>
      <DetailsPage
        img={img5}
        title={"Intelligent energy management for future electric mobility"}
        description={
          "Join the electric vehicle (EV) revolution by providing EV charging solutions to your customers. With our assistance, you can thrive in the rapidly growing EV charging market, address diverse requirements, and cater to multiple user segments. Stay at the forefront of industry developments and secure your "
        }
      ></DetailsPage>
      <Modal title={"Utilities  "}></Modal>
    </div>
  );
}
