import React from 'react';
import CoverFlowSwipper from '../components/swiper/CoverFlowSwipper';

export default function ModelsPage() {
  return (
    <div className='py-28 '>
      <h1 className='text-2xl lg:text-4xl w-10/12 lg:w-6/12  text-center m-auto font-semibold mb-3 lg:mb-8'>Choose the charger that aligns with your needs</h1>
      <p className='w-10/12 lg:w-7/12 text-base lg:text-lg text-[#848484] font-semibold text-center m-auto mb-6 lg:mb-16'>Our solutions boast high compatibility with more than 321 charger models. Our product range is OCPP certified and can seamlessly integrate with both AC and DC chargers, providing you with the flexibility to select the ideal solution for your specific needs</p>
      <div className='border-b-2 border-[#6BB85E]'></div>
<CoverFlowSwipper></CoverFlowSwipper>
      <div className='border-b-2 border-[#6BB85E] mb-10'></div>
      <p className='w-10/12 lg:w-7/12 text-sm lg:text-base text-[#848484] font-semibold text-center m-auto lg:mb-0 mb-2'>Choose Click & Plug for the assurance of a perfect blend of our top-tier design and service, paired with the reliability and performance you expect from your preferred luggage.</p>
      <p className='w-10/12 lg:w-7/12 text-sm lg:text-base text-[#212A4F] font-semibold text-center m-auto mb-5 lg:mb-12'>
      Say no to compromises with a one-size-fits-all solution. Choosing Click & Plug means opting for a company that specializes in software and provides a truly hardware-independent CPMS
      </p>
      {/* <div className="flex justify-center"> 
        <motion.button
         whileHover={{ scale: 1.1 }}
         transition={{ type: "spring", stiffness: 400, damping: 10 }}
        className='bg-[#212A4F] rounded-full w-10/12 lg:w-2/12 py-4 lg:py-6 drop-shadow-lg text-white'>Explore Our Models</motion.button>
      </div> */}
    </div>
  );
}
