import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Logo from "../../assets/logo.png";
import Dropdown from "../dropdown/Dropdown";
import MobileMenu from "../navbar/MobileNavbar";

import { companyOptions, solutionsOptions } from "../../utils/dropdownOptions";
import useWindowSize from "../../hooks/useWindowSize";

export default function Navbar() {
  const [selected, setSelected] = useState("");
  const width = useWindowSize().width;

  return (
    <div className="relative">
      {width <= 768 ? (
        <MobileMenu></MobileMenu>
      ) : (
        <div className="flex items-center absolute bg-white w-full text-sm  font-normal text-[#232C4E] justify-between px-8 lg:px-40 h-20">
          {/* LOGO */}
          <Link to={"/"} onClick={() => setSelected("")}>
            {" "}
            <img src={Logo} className="h-8 lg:h-8" alt="logo"></img>
          </Link>
          <div className="flex items-center gap-x-5">
            {/* navbar items */}
            <ul className="flex items-center lg:mr-16   gap-x-8">
              <Link to="/">
                {" "}
                <li
                  onClick={() => setSelected("home")}
                  className={`cursor-pointer   hover:font-semibold hover:underline ${
                    selected === "about"
                      ? "font-semibold underline"
                      : "font-normal"
                  }`}
                >
                  Home
                </li>
              </Link>

              <li onClick={() => setSelected("solutions")}>
                <Dropdown
                  items={solutionsOptions}
                  name={"Solutions"}
                ></Dropdown>
              </li>

              <Link to="/features">
                <li
                  onClick={() => setSelected("features")}
                  className={`cursor-pointer  hover:font-semibold hover:underline ${
                    selected === "features"
                      ? "font-semibold underline"
                      : "font-normal"
                  }`}
                >
                  Features
                </li>
              </Link>
              <Link to="/about">
                <li
                  onClick={() => setSelected("contact")}
                  className={`cursor-pointer  hover:font-semibold  ${
                    selected === "contact" ? "font-semibold " : "font-normal"
                  }`}
                >
                  Contact
                </li>
              </Link>
              <Link to="https://demo.clickandplug.com/client/login">
                <li
                  onClick={() => setSelected("login")}
                  className={`cursor-pointer  hover:font-semibold  ${
                    selected === "login" ? "font-semibold " : "font-normal"
                  }`}
                >
                  Login
                </li>
              </Link>
            </ul>

            <Link to="/login">
              {" "}
              <motion.button
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="bg-[#232c4e] rounded-xl py-3 hover:bg-[#232c4ef1] hover:rounded-md text-white text-xs px-4"
              >
                Get Started
              </motion.button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
