import React from 'react'
import PageLayout from '../layouts/PageLayout'
import DetailsPage2 from './DetailsPage2'

export default function Casestudies() {
  return (
    <div>
    
    
    <DetailsPage2 title={"Case Studies"} description={"Discover valuable blog articles, the latest news, and industry perspectives concerning electric vehicle charging and the electric vehicle ecosystem."}></DetailsPage2>
    
    
    </div>
  )
}
