import React from 'react'
import { swiperItems } from '../utils/swiperItems'
import SwiperCustom from '../components/swiper/SwiperCustom'

export default function SwipePage() {
  return (
    <div className='bg-[#1f242c] overflow-hidden'>
  
      <div className='mb-20 mt-10'>
      <SwiperCustom items={swiperItems} direction={false}></SwiperCustom>
      </div>

    </div>
  )
}
