import React from "react";
import DetailsPage from "./DetailsPage";
import img8 from "../assets/img8.png";
import Modal from "../components/modal/Modal";
export default function ResidentalPage() {
  return (
    <div>
      <DetailsPage
        img={img8}
        title={"Electric vehicle chargers for residents."}
        description={
          "Enhance your property for residents who drive electric vehicles with Click&Plug's dependable and user-friendly home EV charging solution. Our CPMS streamlines charger maintenance, automates billing and payments, and adapts pricing and access according to your requirements."
        }
      ></DetailsPage>
      <Modal title={"Residental"}></Modal>
    </div>
  );
}
