import React from "react";
import DetailsPage from "./DetailsPage";
import img6 from "../assets/img6.jpg";
import PageLayout from "../layouts/PageLayout";
import Modal from "../components/modal/Modal";
export default function Homecharging() {
  return (
    <div>
      <DetailsPage
        img={img6}
        title={"Stay in control of your at-home EV charging experience"}
        description={
          "Unleash the full potential of your EV charger with Clic&Plug. Our intelligent charging system adjusts to grid demand, energy costs, and your preferences, enhancing efficiency. Monitor charging patterns, save on costs, and eliminate hidden charges with the Click&Plug app, delivering an unparalleled charging experience."
        }
      ></DetailsPage>
      <Modal title={"Home charging"}></Modal>
    </div>
  );
}
