import React, { useState } from "react";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";

export default function MobileNavbar() {
  const [open, setOpen] = useState(false);
  const [solution, setSolution] = useState(false);
  const [resources, setResources] = useState(false);
  const [company, setCompany] = useState(false);

  const [active, setActive] = useState("");

  const handleSolution = () => {
    setSolution(!solution);
    setCompany(false);
    setResources(false);
    setActive("solution");
  };

  return (
    <div
      className={`bg-white ${
        open ? "h-full " : "h-14"
      }  fixed w-screen z-50 flex flex-col`}
    >
      <div className="flex items-center py-3 px-8  justify-between">
        <Link to={"/"}>
          <img src={logo} className="h-8" alt="logo" />
        </Link>
        <BiMenu size={30} onClick={() => setOpen(!open)} />
      </div>
      {open && (
        <div className="flex items-center justify-center  mb-16 mt-8 w-screen">
          <ul className="space-y-5 ">
            <li
              onClick={() => {
                setActive("about");
                setOpen(false);
                setSolution(false);
              }}
              className={`${
                active === "about" ? "bg-[#232c4ea0]" : "bg-[#232C4E]"
              }  p-2   rounded-md w-[300px] text-center  text-white `}
            >
              <Link to="/about">About</Link>
            </li>
            <li
              className={`${
                solution ? "bg-[#232c4ea0]" : "bg-[#232C4E]"
              }  p-2   rounded-md w-[300px] text-center  text-white `}
              onClick={handleSolution}
            >
              Solutions
            </li>
            {solution && (
              <div>
                <ul className="text-sm text-gray-400 space-y-3 p-2 rounded-lg text-start">
                  <li>
                    <Link
                      to={"/Residental"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Residental
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/workplace"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Workplace & offices
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/commercial"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Commercial
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/fleet"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Fleet
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/chargepoint"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Charge point insallers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/operators"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Operators
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/utilities"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Utilities
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/homecharging"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Home Charging
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/roamingnetwork"}
                      className=" p-2 rounded-lg  hover:bg-[#EDEDED]"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Roaming network
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <li
              onClick={() => {
                setActive("features");
                setOpen(false);
                setSolution(false);
              }}
              className={`${
                active === "features" ? "bg-[#232c4ea0]" : "bg-[#232C4E]"
              }  p-2   rounded-md w-[300px] text-center  text-white `}
            >
              <Link to={"/features"}>Features</Link>
            </li>
            <li
              className={`${
                active === "bookingDemo" ? "bg-[#232c4ea0]" : "bg-[#232C4E]"
              }  p-2   rounded-md w-[300px] text-center  text-white `}
              onClick={() => {
                setSolution(false);
                setActive("bookingDemo");
                setOpen(false);
              }}
            >
              {" "}
              <Link to={"/login"}>Booking Demo</Link>
            </li>

            <li
              onClick={() => {
                setActive("loginClientDashboard");
                setOpen(false);
                setSolution(false);
              }}
              className={`${
                active === "loginClientDashboard"
                  ? "bg-[#232c4ea0]"
                  : "bg-[#232C4E]"
              }  p-2   rounded-md w-[300px] text-center  text-white `}
            >
              <Link
                to="https://demo.clickandplug.com/client/login"
                target="_blank"
              >
                Login
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
