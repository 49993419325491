import {
  FiChevronDown,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

const Dropdown = ({ name, items }) => {
  const [open, setOpen] = useState(false);
  const {width} = useWindowSize().width

  return (
    <div className="  flex items-center  font-normal text-[#232C4E]  justify-center bg-white">
      <motion.div animate={open ? "open" : "closed"} className="relative">
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="flex items-center  rounded-md    "
        >
          <span className="text-[#212A4F]  hover:font-semibold hover:underline  font-normal">{name}</span>
          <motion.span variants={iconVariants}>
            <FiChevronDown />
          </motion.span>
        </button>

        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: "-50%" }}
          className={`flex flex-col gap-2 p-2 rounded-lg bg-white shadow-xl ${width<= 768  ? "relative" : "absolute z-30 top-[230%] left-[50%]"} w-48 overflow-hidden`}
        >
          {items.map((item,index) => (
            <Link key={index}  to={item.link}>
              
              <Option setOpen={setOpen} Icon={item.icon} text={item.text} />
            </Link>
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Option = ({ text, Icon, setOpen }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => setOpen(false)}
      className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-[#212A4F] text-[#212A4F] hover:text-white transition-colors cursor-pointer"
    >
      <motion.span variants={actionIconVariants}>
        {/* <Icon /> */}
      </motion.span>
      <span>{text}</span>
    </motion.li>
  );
};

export default Dropdown;

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
