import Routing from "./routes/Routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";


function App() {
  useEffect(()=>{
    document.addEventListener("DOMContentLoaded", (event) => {
      window.screenTop(0)
    });
  },[])
  
  return (
    
      <div  className="App">
      
        <Routing></Routing>
        <ToastContainer />
      </div>
   
  );
}

export default App;
