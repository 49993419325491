import React from 'react'
import DetailsPage from './DetailsPage'
import img7 from "../assets/img7.jpg"
import PageLayout from '../layouts/PageLayout'
import Modal from '../components/modal/Modal'
export default function Wholesaler() {
  return (
    <div>

  <DetailsPage img={img7} title={"Power up your workforce"}
      description={"Ready to be the superhero your employees need? Offer workplace charging or reimburse employees charging outside of work to accelerate the green transition."}
      ></DetailsPage>
                  <Modal title={"Wholesaler"}></Modal>

  

    </div>
  )
}
