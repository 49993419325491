import total from "../assets/total.png"
import powerdot from "../assets/powerdot.png"
import allego from "../assets/allego.png"
import evbox from "../assets/evbox.png"
import enbw from "../assets/enbw.png"
import powy from "../assets/powy.png"


export const  swiperItems = [

{
    img:total
},
{
    img:powerdot
},
{
    img:allego
},
{
    img:evbox
},
{
    img:enbw
},
{
    img:powy
},
{
    img:total
},
{
    img:powerdot
},
{
    img:allego
},
{
    img:evbox
},
{
    img:enbw
},
{
    img:powy
},

{
    img:total
},
{
    img:powerdot
},
{
    img:allego
},
{
    img:evbox
},
{
    img:enbw
},
{
    img:powy
},



]