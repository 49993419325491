import React, { useState } from "react";
import location from "../assets/location.png";
import bg3 from "../assets/bg3.jpg";
import { Button, Col, Row } from "antd";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

export default function About() {
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const handleFormSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      );

      toast.success("Message sent successfully.");
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#EDEDED]">
      <img
        alt="about"
        className="h-[400px] object-cover w-full"
        src={bg3}
      ></img>
      <h1 className="text-6xl font-bold  text-white -translate-y-60 text-center mt-0">
        Contact Us
      </h1>

      <h1 className="text-4xl font-bold text-center mt-2 ">Contact Us</h1>
      <Col span={24}>
        <Row>
          <Col lg={24} md={12}>
            <form
              ref={form}
              onSubmit={handleFormSubmit}
              className="w-11/12 lg:w-10/12 m-auto gap-x-10  lg:flex-row flex-col flex items-center  pt-16"
            >
              <div className="!w-full lg:w-1/2 h-[370px] mb- bg-white lg:mb-0 mb-5  rounded-xl">
                <p className="text-base lg:text-lg  bg-[#5DC0CA] px-2 lg:px-10 rounded-t-xl text-white py-4 text-center   lg:mb-5 font-semibold">
                  For all your opinions, requests and suggestions; You can
                  contact us using our contact information below or the message
                  form.
                </p>
                <h4 className="text-lg lg:text-xl px-10 mb-2   lg:mb-5">
                  Click & Plug Ltd
                </h4>
                <div className="flex px-10 items-center mb-2   lg:mb-5">
                  <span className="text-lg font-semibold pr-3">Adress:</span>
                  <p className="text-sm lg:text-base ">
                    12 Sutherland Grove, Wandsworth, London, SW18 5PU
                  </p>
                </div>

                <div className="flex px-10 items-center  mb-2   lg:mb-5">
                  <span className="text-lg font-semibold pr-3">Email:</span>
                  <p className="text-sm lg:text-base">
                    admin@clickAndPlug.co.uk
                  </p>
                </div>
              </div>
              <div className=" bg-[#5DC0CA] !w-full lg:w-1/2 rounded-xl px-5  m-auto space-y-3 overflow-hidden mb-4">
                <h1 className="text-center text-2xl font-bold mt-4 text-white">
                  Send Message
                </h1>
                <div className="flex items-center  text-white">
                  <span className="font-semibold lg:text-base text-sm w-[100px] px-3">
                    Subject:
                  </span>
                  <input
                    required
                    type="text"
                    className=" py-2 lg:px-4 text-black outline-none focus:border-gray-400 focus:border-2 px-2 w-[500px] rounded-xl"
                    placeholder="Write Subject"
                    name="subject"
                  ></input>
                </div>
                <div className="flex items-center  text-white">
                  <span className="font-semibold  lg:text-base text-sm px-3 w-[100px]">
                    Name:
                  </span>
                  <input
                    required
                    type="text"
                    className=" py-2 lg:px-4 outline-none focus:border-gray-400 focus:border-2 text-black px-2 rounded-xl w-[500px]"
                    placeholder="Name / Last Name"
                    name="name"
                  ></input>
                </div>
                <div className="flex items-center  text-white">
                  <span className="font-semibold lg:text-base text-sm px-3 w-[100px] ">
                    Email:
                  </span>
                  <input
                    required
                    type="email"
                    className=" py-2 lg:px-4 outline-none focus:border-gray-400 focus:border-2 text-black px-2 rounded-xl w-[500px]"
                    placeholder="E-Posta"
                    name="email"
                  ></input>
                </div>
                <div className="flex items-center  text-white">
                  <span className="font-semibold lg:text-base text-sm  px-3 w-[100px]">
                    Phone:
                  </span>
                  <input
                    required
                    type="text"
                    className=" py-2 lg:px-4 outline-none focus:border-gray-400 focus:border-2  text-black px-2 rounded-xl w-[500px]"
                    placeholder="Phone Number"
                    name="phone"
                  ></input>
                </div>
                <div className="flex items-center  text-white">
                  <span className="font-semibold lg:text-base text-sm  px-3 w-[100px]">
                    Message:
                  </span>
                  <textarea
                    required
                    type="text"
                    className=" py-4 lg:px-4 outline-none focus:border-gray-400 focus:border-2  text-black px-2 rounded-xl w-[500px]"
                    placeholder="Message"
                    name="message"
                  ></textarea>
                </div>
                <div className="flex items-center  text-white !my-4 justify-center ">
                  <Button
                    htmlType="submit"
                    type="primary"
                    className=""
                    disabled={isLoading}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Col>
      <div className="relative">
        <img
          src={location}
          alt="location"
          className="w-screen h-[600px] py-5 pt-16 object-cover object-center"
        ></img>
        <span className="absolute  text-red-500 lg:top-80 lg:right-[545px] top-[310px] right-[25px]">
          Click & Plug
        </span>
      </div>
    </div>
  );
}
