import React from 'react'
import DetailsPage from './DetailsPage'
import img4 from "../assets/img4.jpg"
import PageLayout from '../layouts/PageLayout'
import Modal from '../components/modal/Modal'
export default function ChargePoint() {
  return (
    <div>
 
  <DetailsPage img={img4} title={"EV charging software built for installers"}
      description={"Are you exhausted from grappling with intricate systems and daily obstacles while setting up charge points? As a charge point installer, your time is precious, and you require an efficient and user-friendly solution. That's why Click&Plug's Charge Point Management System (CPMS) is crafted to flexibly cater to your requirements"}
      ></DetailsPage>
                  <Modal title={"Charge point installers"}></Modal>

  

    </div>
  )
}
