import React from 'react'
import PageLayout from '../layouts/PageLayout'
import DetailsPage2 from './DetailsPage2'

export default function Press() {
  return (
    <div>
<div>


    
    <DetailsPage2 title={"Press"} description={"Discover valuable blog articles, the latest news, and industry insights on electric vehicle (EV) charging and the EV ecosystem."}></DetailsPage2>
    
   
    </div>

    </div>
  )
}
