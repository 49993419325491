import emailjs from "@emailjs/browser";
import { Button } from "antd";
import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import demoCapture from "../assets/demo-capture.jpg";
import useWindowSize from "../hooks/useWindowSize";

export default function LoginPage() {
  const form = useRef();
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_BOOKDEMO_TEMPLATE,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      );

      toast.success("Message sent successfully.");
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      ref={form}
      className="flex  justify-between"
      onSubmit={handleFormSubmit}
    >
      {width > 900 && (
        <>
          <div
            className="w-1/2 !mb-4 !h-[110vh]"
            style={{
              backgroundImage: `url(${demoCapture})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      )}
      <div className="w-full md:!w-1/2 mt-20 mb-20 m-auto !px-6">
        <h1 className="text-3xl font-semibold mt-10 text-[#232C4E] mb-5">
          Book Your Live Demo
        </h1>

        <div className="flex items-center  ">
          <div className="!w-full md:w-1/2">
            <span className="text-sm">First Name*</span>
            <input
              required
              placeholder="First Name"
              type="text"
              name="firstName"
              className="w-full py-1 border-2  text-sm px-2 rounded-md mr-2"
            ></input>
          </div>
          <div className="!w-full md:w-1/2 pl-4">
            <span className="text-sm">Last Name*</span>
            <input
              name="lastName"
              placeholder="Last Name"
              type="text"
              className="w-full py-1 border-2  text-sm px-2 rounded-md mr-2"
              required
            ></input>
          </div>
        </div>

        <div className="flex items-center !w-full my-4">
          <div className="!w-full md:w-1/2">
            <span className="text-sm">Business Email*</span>
            <input
              placeholder="Business Email"
              type="text"
              name="email"
              required
              email={true}
              className="w-full py-1 text-sm border-2  px-2 rounded-md mr-2"
            ></input>
          </div>
          <div className="!w-full md:w-1/2 px-4">
            <span className="text-sm">Phone Number*</span>
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
                size: "large",

                placeholder: "Phone Number",
              }}
            />
          </div>
        </div>

        <div className="!w-full !flex my-4">
          <div className="!w-full md:w-1/2">
            <span className="text-sm">Company Name*</span>
            <input
              placeholder="Company Name"
              type="text"
              name="companyName"
              className="w-full py-1 text-sm border-2 mb-2  px-2 rounded-md"
            ></input>
          </div>
          <div className="!w-full md:w-1/2 px-4">
            <span className="text-sm">Business Type*</span>

            <input
              placeholder="Business Type"
              name="businessType"
              type="text"
              className="w-full py-1 text-sm border-2 mb-2 px-2 rounded-md"
            ></input>
          </div>
        </div>
        <div className=" mb-2 my-4">
          <div className="w-full mb-4">
            <h6 className="mb-3 lg:text-base text-sm">
              How many chargers do you plan to operate within the next 2 years?
            </h6>
            <input
              type="range"
              name="chargerCount"
              id="range"
              min="0"
              max="4"
              step="1"
              defaultValue={0}
              className="w-full"
            />
            <div className="flex w-full justify-between text-sm">
              <span>0-50</span>
              <span>50-100</span>
              <span>100-500</span>
              <span>500-2000</span>
              <span>2000+</span>
            </div>
          </div>
        </div>

        <textarea
          name="description"
          rows={4}
          className=" border-2 !w-full !pl-2 !pt-2 rounded-xl  text-sm"
          placeholder="Please describe your business case briefly*"
        ></textarea>

        {/* <div className="flex items-center  mb-5">
          <input className="h-4 w-4" type="checkbox"></input>
          <p className="text-sm ml-2">
            Sign me up for cool updates and knowledge resources by email
          </p>
        </div> */}

        <p className="text-[9px] mb-10">
          By clicking the "Book Live Demo" button below, you acknowledge that
          you have reviewed and comprehended our Privacy Policy and Terms and
          Conditions. By selecting the checkbox above, you grant Click & Plug
          permission to utilize your email and/or phone number for contacting
          you regarding our products, services, events, or materials associated
          with your demo request. You retain the option to opt out of these
          communications at any time. For further details on how to unsubscribe
          and to learn more about our privacy policies and our commitment to
          safeguarding your privacy, please refer to our Privacy Policy.
        </p>

        <div className="w-full flex items-center justify-center">
          <button
            disabled={isLoading}
            type="submit"
            className="py-4 text-sm px-4 bg-[#232C4E] rounded-md text-white hover:bg-[#405291]"
          >
            Send Email
          </button>
        </div>
      </div>
    </form>
  );
}
