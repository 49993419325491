import React from "react";
import HomePage from "./HomePage";
import SolutionPage from "./SolutionPage";
import MockupPage from "./MockupPage";
import SwipePage from "./SwipePage";
import ProgressPage from "./ProgressPage";
import ModelsPage from "./ModelsPage";
import PageLayout from "../layouts/PageLayout";

export default function LandingPage() {
  return (
    <div>
     
        <HomePage></HomePage>
        <SolutionPage></SolutionPage>
        <MockupPage></MockupPage>
        <SwipePage></SwipePage>
        <ProgressPage></ProgressPage>
        <ModelsPage></ModelsPage>
     
    </div>
  );
}
