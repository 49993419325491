import React from 'react';
import bghome from "../assets/bghome.jpg";
import HomeCard from '../components/homecard/HomeCard';

export default function HomePage() {
  return (
    <div className=' bg-cover h-[100vh]  bg-center  lg:h-screen '
    style={{backgroundImage : `url(${bghome})`}}
    >

        <HomeCard></HomeCard>
     
    </div>
  );
}
