import { BsFillPeopleFill } from "react-icons/bs";
import {BiHomeAlt2} from "react-icons/bi"
import {FiMonitor} from "react-icons/fi"
import {FiSettings} from "react-icons/fi"
import {LuHotel} from "react-icons/lu"
import {GoPeople} from "react-icons/go"
import {BsShop} from "react-icons/bs"
import {GoLightBulb} from "react-icons/go"
import {AiOutlineCar} from "react-icons/ai"
export const solutionCardItems = [
  {
    icon: <BiHomeAlt2 color={"#FEB5CF"} size={25}></BiHomeAlt2>,
    title: "Home Charging",
    description: "Turn your charging aspirations into reality.",
    color:"#FEB5CF"
  },
  {
    icon: <FiMonitor color={"#02A78B"} size={25}></FiMonitor>,
    title: "Workplace & offices",
    description: "Enhance both user satisfaction and the profitability",
    color:"#02A78B"
  },
  {
    icon: <FiSettings color={"#687DFE"} size={25}></FiSettings>,
    title: "Installers",
    description: "Efficiently set up charging locations of any scale in less time",
    color:"#687DFE"
  },
  {
    icon: <LuHotel color={"#FEB5CF"}  size={25}></LuHotel>,
    title: "Residental",
    description: "Elevate your facilities to accommodate residents who own electric vehicles.",
    color:"#FEB5CF"

  },
  {
    icon: <GoPeople  color={"#687DFE"} size={25}></GoPeople>,
    title: "Charge point operators",
    description: "Delivering dependable and effective charging solutions to your clientele",
    color:"#687DFE"
  },
  {
    icon: <BsShop  color={"#02A78B"} size={25}></BsShop>,
    title: "Commercial",
    description: "Increase visitor flow with the appropriate charging solution",
    color:"#02A78B"
  },
  {
    icon: <GoLightBulb  color={"#FEB5CF"} size={25}></GoLightBulb>,
    title: "Utilities",
    description: "Maintain a competitive edge by offering electric vehicle charging solutions.",
    color:"#FEB5CF"
  },
  {
    icon: <AiOutlineCar  color={"#687DFE"} size={25}></AiOutlineCar>,
    title: "Fleet",
    description: "Effectively handle the energy requirements of your electric vehicle",
    color:"#687DFE"
  },
];
