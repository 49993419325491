import item1 from "../assets/item1.png";
import item2 from "../assets/item2.png";

import item3 from "../assets/item3.png";

import item4 from "../assets/item4.png";

import item5 from "../assets/item5.png";

import item6 from "../assets/item6.png";




export const  modelSwipperItems = [

{
    img:item1
},
{
    img:item2
},
{
    img:item3
},
{
    img:item4
},
{
    img:item5
},
{
    img:item6
},

{
    img:item5
},

{
    img:item4
},

{
    img:item3
},
{
    img:item2
},




]