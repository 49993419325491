import React from 'react'
import { modelSwipperItems } from '../../utils/modelSwipperItem'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css'
import 'swiper/css/effect-coverflow'

import 'swiper/css/pagination'

import 'swiper/css/navigation'


import { EffectCoverflow,Pagination,Navigation ,Autoplay} from 'swiper/modules'
import useWindowSize from '../../hooks/useWindowSize';
export default function CoverFlowSwipper() {
    const {width} = useWindowSize()

  return (
    <div>
      <Swiper
       slidesPerView={
        width <= 768 ? 3 :
        (width <= 960 && width > 768 ? 4 :
        (width <= 1024 ? 4 : 4))
      }
      autoplay={{
        delay: 1000,

        disableOnInteraction: true,
       
        loop: true,
      }}
      modules={[EffectCoverflow,Pagination,Navigation ,Autoplay]}
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
     
      spaceBetween={50}
      coverflowEffect={
       { rotate:-20,
        stretch:0,
        depth:0,
        modifier:0
        ,
    slideShadows:true}
      }
      
      
      >
      {modelSwipperItems.map((item)=>(
          <SwiperSlide className='items-center justify-center flex'>
            <img src={item.img}></img>
          </SwiperSlide>
      ))}
      </Swiper>
    </div>
  )
}
